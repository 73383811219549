body {
  background-color: black;
  margin: 0;
}
html {
  scroll-behavior: smooth;
  margin: 0;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Forma DJR Micro";
  font-weight: normal;
  font-size: 20px;
  color: white;
}

@font-face {
  font-family: "Forma DJR Micro";
  src: url("fonts/FormaDJRMicro-Regular.woff2") format("woff2"),
    url("fonts/FormaDJRMicro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Handel Gothic D";
  src: url("fonts/HandelGotD-Bold.woff2") format("woff2"),
    url("fonts/HandelGotD-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@property --gradientBlack {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}
@property --gradientTransparent {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --bgLogoText {
  syntax: "<url>";
  inherits: false;
  initial-value: url("");
}

:root {
  --scroll-offset: 100px;
  --violet-color: #be1fdf;
}
body p {
  word-wrap: break-word;
  white-space: pre-line;
}
body a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: inherit;
}
canvas.sigCanvas {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 21474836449 !important;
  background-color: white;
}
@media screen and (orientation: portrait) {
  canvas.sigCanvas {
    visibility: hidden;
  }
}
